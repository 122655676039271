import React from "../../../_snowpack/pkg/react.js";
import "./Arrow.css";
import {ArrowIcon} from "../../assets/index.js";
export const Arrow = () => {
  return /* @__PURE__ */ React.createElement("div", {
    className: "arrow-container"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "arrow-ring"
  }, /* @__PURE__ */ React.createElement(ArrowIcon, {
    className: "arrow"
  })));
};
