import React, {useEffect, useState} from "../_snowpack/pkg/react.js";
import {Header, Scroll} from "./components/index.js";
import {About, Contact, Hero, Intro, Projects} from "./containers/index.js";
import {userAgentMobile} from "./utils/userAgentMobile.js";
export const App = () => {
  const [cursorPosition, setCursorPosition] = useState({pageY: -100, pageX: -100});
  const {pageY, pageX} = cursorPosition;
  useEffect(() => {
    document.addEventListener("mouseleave", () => {
      setCursorPosition({pageY: -100, pageX: -100});
    });
    document.addEventListener("mousemove", (event) => {
      setCursorPosition({pageY: event.clientY - 20, pageX: event.clientX - 20});
    });
  }, []);
  const [displayApp, setDisplayApp] = useState(false);
  setTimeout(() => {
    setDisplayApp && setDisplayApp(true);
    document.body.style.background = "#151515";
  }, 1550);
  useEffect(() => {
    displayApp && console.clear();
  }, [displayApp]);
  const [introComplete, setIntroComplete] = useState(false);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Header, null), /* @__PURE__ */ React.createElement(Intro, {
    setIntroComplete
  }), displayApp ? userAgentMobile ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Hero, {
    introComplete
  }), /* @__PURE__ */ React.createElement(About, null), /* @__PURE__ */ React.createElement(Projects, null), /* @__PURE__ */ React.createElement(Contact, null)) : /* @__PURE__ */ React.createElement(Scroll, null, /* @__PURE__ */ React.createElement(Hero, {
    introComplete
  }), /* @__PURE__ */ React.createElement(About, null), /* @__PURE__ */ React.createElement(Projects, null), /* @__PURE__ */ React.createElement(Contact, null)) : null, !userAgentMobile && /* @__PURE__ */ React.createElement("div", {
    className: "cursor",
    style: {top: pageY, left: pageX}
  }));
};
