import React, {useEffect, useRef} from "../../../_snowpack/pkg/react.js";
import hoverEffect from "../../../_snowpack/pkg/hover-effect.js";
import {gsap, ScrollTrigger} from "../../../_snowpack/pkg/gsap/all.js";
import AOS from "../../../_snowpack/pkg/aos.js";
import "../../../_snowpack/pkg/aos/dist/aos.css";
import {GithubIcon} from "../../assets/index.js";
import {Arrow} from "../index.js";
import "./Project.css";
export const Project = ({
  project: {title, description1, description2, list1, list2, repo, website, image1, image2}
}) => {
  useEffect(() => AOS.init({once: true}), []);
  useEffect(() => {
    new hoverEffect({
      parent: document.querySelector(`.${title}`),
      intensity1: 0.1,
      intensity2: 0.1,
      angle2: Math.PI / 2,
      imagesRatio: 0.625,
      easing: "power3.out",
      speedIn: 0.8,
      speedOut: 0.8,
      image1,
      image2,
      displacementImage: `/dist/assets/images/distort-${Math.floor(Math.random() * 2) + 1}.jpg`
    });
  }, [image1, image2, title]);
  const imageCover1 = useRef();
  const imageCover2 = useRef();
  useEffect(() => {
    const titleAnimation = (element) => {
      gsap.registerPlugin(ScrollTrigger);
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          start: "top 80%"
        }
      });
      tl.to(element, {
        duration: 1,
        y: "-100%",
        ease: Expo.easeInOut
      });
    };
    titleAnimation(imageCover1.current);
    titleAnimation(imageCover2.current);
  }, []);
  return /* @__PURE__ */ React.createElement("article", {
    className: "project"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "project-details",
    "data-aos": "fade-up",
    "data-aos-offset": "250",
    "data-aos-duration": "800",
    "data-aos-easing": "ease-in-out"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "project-details-container"
  }, /* @__PURE__ */ React.createElement("h3", {
    className: "project-details-title"
  }, title), /* @__PURE__ */ React.createElement("p", {
    className: "project-details-description"
  }, description1), /* @__PURE__ */ React.createElement("p", {
    className: "project-details-description"
  }, description2), /* @__PURE__ */ React.createElement("h4", {
    className: "project-details-features"
  }, "Tech Stack and Features:"), /* @__PURE__ */ React.createElement("div", {
    className: "project-details-features-lists"
  }, /* @__PURE__ */ React.createElement("ul", {
    className: "list"
  }, list1.map((item) => /* @__PURE__ */ React.createElement("li", {
    key: item
  }, "- ", item))), /* @__PURE__ */ React.createElement("ul", {
    className: "list"
  }, list2.map((item) => /* @__PURE__ */ React.createElement("li", {
    key: item
  }, "- ", item)))), /* @__PURE__ */ React.createElement("p", {
    className: "github"
  }, /* @__PURE__ */ React.createElement("a", {
    href: repo,
    rel: "noreferrer",
    target: "_blank"
  }, /* @__PURE__ */ React.createElement("span", null, "Link to Repo:"), /* @__PURE__ */ React.createElement(GithubIcon, null)))), /* @__PURE__ */ React.createElement(Arrow, null)), /* @__PURE__ */ React.createElement("div", {
    className: `project-image project-image-large project-image-${title}`
  }, /* @__PURE__ */ React.createElement("a", {
    href: website,
    target: "_blank",
    rel: "noreferrer"
  }, /* @__PURE__ */ React.createElement("div", {
    className: `canvas-container ${title}`
  }))), /* @__PURE__ */ React.createElement("div", {
    className: `project-image project-image-mobile`
  }, /* @__PURE__ */ React.createElement("a", {
    href: website,
    target: "_blank",
    rel: "noreferrer"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "project-image-mobile-container cover-top"
  }, /* @__PURE__ */ React.createElement("img", {
    src: image1,
    alt: "Screenshot of the project"
  }), /* @__PURE__ */ React.createElement("div", {
    className: "project-image-mobile-container-cover ",
    ref: imageCover1
  })), /* @__PURE__ */ React.createElement("div", {
    className: "project-image-mobile-container"
  }, /* @__PURE__ */ React.createElement("img", {
    src: image2,
    alt: "Screenshot of the project"
  }), /* @__PURE__ */ React.createElement("div", {
    className: "project-image-mobile-container-cover",
    ref: imageCover2
  })))));
};
