import React, {useEffect} from "../../../_snowpack/pkg/react.js";
import gsap from "../../../_snowpack/pkg/gsap.js";
import "./Intro.css";
import {Expo} from "../../../_snowpack/pkg/gsap/all.js";
export const Intro = ({
  setIntroComplete
}) => {
  useEffect(() => {
    setTimeout(() => {
      document.body.style.overflow = "hidden";
    }, 500);
    const handleComplete = () => {
      setIntroComplete(true);
      setTimeout(() => {
        const cursor = document.querySelector(".cursor");
        if (cursor)
          cursor.style.display = "block";
        document.body.style.overflow = "unset";
      }, 1e3);
    };
    const tl = gsap.timeline({onComplete: handleComplete});
    tl.to(".welcome", {
      delay: 1,
      duration: 0.5,
      ease: Expo.easeInOut,
      opacity: 1
    });
    tl.to(".letter", {
      delay: 1.5,
      duration: 1,
      ease: Expo.easeInOut,
      opacity: 0,
      stagger: {
        each: 0.1,
        from: "random"
      }
    });
    tl.to(".back", {
      duration: 1.5,
      ease: Expo.easeInOut,
      y: "-120%"
    });
  }, [setIntroComplete]);
  return /* @__PURE__ */ React.createElement("section", {
    className: "intro"
  }, /* @__PURE__ */ React.createElement("h1", {
    className: "welcome"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "letter"
  }, "W"), /* @__PURE__ */ React.createElement("span", {
    className: "letter"
  }, "e"), /* @__PURE__ */ React.createElement("span", {
    className: "letter"
  }, "l"), /* @__PURE__ */ React.createElement("span", {
    className: "letter"
  }, "c"), /* @__PURE__ */ React.createElement("span", {
    className: "letter"
  }, "o"), /* @__PURE__ */ React.createElement("span", {
    className: "letter"
  }, "m"), /* @__PURE__ */ React.createElement("span", {
    className: "letter"
  }, "e")), /* @__PURE__ */ React.createElement("div", {
    className: "back"
  }));
};
