import React from "../../../_snowpack/pkg/react.js";
import {Project} from "../../components/Project/Project.js";
import {projects} from "../../data/projects.js";
import "./Projects.css";
import {Title} from "../../components/index.js";
export const Projects = () => {
  return /* @__PURE__ */ React.createElement("section", {
    id: "projects",
    className: "projects"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "container"
  }, /* @__PURE__ */ React.createElement(Title, {
    title: "My Projects"
  }), /* @__PURE__ */ React.createElement("div", {
    className: "projects"
  }, projects.map((project) => /* @__PURE__ */ React.createElement(Project, {
    key: project.title,
    project
  })))));
};
