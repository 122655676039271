import React, {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import AOS from "../../../_snowpack/pkg/aos.js";
import "../../../_snowpack/pkg/aos/dist/aos.css";
import {Title} from "../../components/index.js";
import "./Contact.css";
export const Contact = () => {
  useEffect(() => AOS.init({once: true}), []);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    const data = JSON.stringify({name, email, message});
    const handleError = (error2) => {
      setError(error2);
      setShowErrorMessage(true);
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 5e3);
    };
    fetch("https://formspree.io/f/meqvqvab", {
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json"
      }
    }).then((response) => {
      console.log(response);
      setSubmitting(false);
      if (response.status === 200) {
        setMessage("");
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 5e3);
        return;
      }
      handleError("There was a problem sending your message.");
    }).catch((error2) => {
      console.error(error2);
      setSubmitting(false);
      handleError("There was a problem sending your message.");
    });
  };
  return /* @__PURE__ */ React.createElement("section", {
    id: "contact",
    className: "contact"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "container"
  }, /* @__PURE__ */ React.createElement(Title, {
    title: "Contact Me"
  }), /* @__PURE__ */ React.createElement("div", {
    className: "contact-form-container"
  }, /* @__PURE__ */ React.createElement("form", {
    className: "contact-form",
    id: "contact",
    onSubmit: handleSubmit
  }, /* @__PURE__ */ React.createElement("p", {
    className: "hello",
    "data-aos": "fade-up",
    "data-aos-offset": "250",
    "data-aos-duration": "800",
    "data-aos-easing": "ease-in-out"
  }, "Hello,"), /* @__PURE__ */ React.createElement("div", {
    className: "contact-form-item",
    "data-aos": "fade-up",
    "data-aos-offset": "250",
    "data-aos-duration": "800",
    "data-aos-easing": "ease-in-out"
  }, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "name",
    className: "contact-form-item-label"
  }, "My name is"), /* @__PURE__ */ React.createElement("input", {
    type: "text",
    id: "name",
    name: "name",
    className: "contact-form-item-input",
    placeholder: "Enter your name here",
    value: name,
    onChange: ({target}) => setName(target.value),
    required: true
  })), /* @__PURE__ */ React.createElement("div", {
    className: "contact-form-item",
    "data-aos": "fade-up",
    "data-aos-offset": "250",
    "data-aos-duration": "800",
    "data-aos-easing": "ease-in-out"
  }, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "email",
    className: "contact-form-item-label"
  }, "Here is my email"), /* @__PURE__ */ React.createElement("input", {
    type: "email",
    id: "email",
    name: "email",
    className: "contact-form-item-input",
    placeholder: "Enter your email here",
    value: email,
    onChange: ({target}) => setEmail(target.value),
    required: true
  })), /* @__PURE__ */ React.createElement("div", {
    className: "contact-form-item",
    "data-aos": "fade-up",
    "data-aos-offset": "250",
    "data-aos-duration": "800",
    "data-aos-easing": "ease-in-out"
  }, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "message",
    className: "contact-form-item-label "
  }, "Let's talk about"), /* @__PURE__ */ React.createElement("input", {
    type: "text",
    id: "message",
    name: "message",
    className: "contact-form-item-input message",
    placeholder: "Enter your message here",
    value: message,
    onChange: ({target}) => setMessage(target.value),
    required: true
  })), /* @__PURE__ */ React.createElement("div", {
    className: "button-container",
    "data-aos": "fade-up",
    "data-aos-offset": "100",
    "data-aos-duration": "800",
    "data-aos-easing": "ease-in-out"
  }, /* @__PURE__ */ React.createElement("button", {
    type: "submit",
    className: "contact-form-submit",
    disabled: submitting
  }, "Send")), /* @__PURE__ */ React.createElement("div", {
    className: "form-message-container"
  }, /* @__PURE__ */ React.createElement("p", {
    className: `form-message ${showSuccessMessage ? "visible" : ""}`
  }, "Thank you for the message!"), /* @__PURE__ */ React.createElement("p", {
    className: `form-message ${showErrorMessage ? "visible" : ""}`
  }, error)), " "))));
};
