import React, {useEffect} from "../../../_snowpack/pkg/react.js";
import AOS from "../../../_snowpack/pkg/aos.js";
import "../../../_snowpack/pkg/aos/dist/aos.css";
import "./About.css";
import {Title} from "../../components/index.js";
export const About = () => {
  useEffect(() => AOS.init({once: true}), []);
  return /* @__PURE__ */ React.createElement("section", {
    id: "about",
    className: "about"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "container"
  }, /* @__PURE__ */ React.createElement(Title, {
    title: "About Me"
  }), /* @__PURE__ */ React.createElement("div", {
    className: "content"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "about-content",
    "data-aos": "fade-up",
    "data-aos-offset": "250",
    "data-aos-duration": "800",
    "data-aos-easing": "ease-in-out"
  }, "Learning to code has been a tough journey. With the help of", " ", /* @__PURE__ */ React.createElement("a", {
    href: "https://www.youtube.com/channel/UCSKr_gILoWAETdYpAoCxB0w/channels?view=56&shelf_id=0",
    target: "blank",
    rel: "noreferrer",
    className: "link"
  }, "developers on YouTube,"), " ", "I have learnt web development from scratch. I remember once being overwhelmed by HTML and SCSS, now I'm creating this website in React and TypeScript."), /* @__PURE__ */ React.createElement("p", {
    className: "about-content",
    "data-aos": "fade-up",
    "data-aos-offset": "250",
    "data-aos-duration": "800",
    "data-aos-easing": "ease-in-out"
  }, "Through YouTube, Udemy, Scrimba, and other resources, I have learnt several technologies. HTML, CSS, SCSS, Styled Components, JavaScript, ES6+, React, Redux, NodeJs, Express and MongoDB, just to name a few."), /* @__PURE__ */ React.createElement("p", {
    className: "about-content",
    "data-aos": "fade-up",
    "data-aos-offset": "250",
    "data-aos-duration": "800",
    "data-aos-easing": "ease-in-out"
  }, "I'm a quick learner and eager to find out new things. I'm never satisfied with mediocre. If I do something, I do it with perfection. Look below."))));
};
