import React, {useEffect, useRef} from "../../../_snowpack/pkg/react.js";
import {useScroll} from "../../hooks/useScroll.js";
import {useWindowSize} from "../../hooks/useWindowSize.js";
import "./Scroll.css";
export const Scroll = ({children}) => {
  const size = useWindowSize();
  const app = useRef(null);
  const scrollContainer = useRef(null);
  const setBodyHeight = () => {
    document.body.style.height = `${scrollContainer?.current?.getBoundingClientRect().height}px`;
  };
  useEffect(() => {
    setBodyHeight();
  }, [size]);
  const positionY = useScroll();
  useEffect(() => {
    if (scrollContainer.current)
      scrollContainer.current.style.transform = `translate3d(0, -${positionY}px, 0)`;
  }, [positionY]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "app-container",
    ref: app
  }, /* @__PURE__ */ React.createElement("div", {
    className: "scroll-container",
    ref: scrollContainer
  }, children));
};
