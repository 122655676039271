import React, {useEffect, useRef} from "../../../_snowpack/pkg/react.js";
import "./Title.css";
import {gsap, ScrollTrigger} from "../../../_snowpack/pkg/gsap/all.js";
import {useWindowSize} from "../../hooks/useWindowSize.js";
export const Title = ({title}) => {
  const {width} = useWindowSize();
  const titleEl = useRef();
  const lineEl = useRef();
  useEffect(() => {
    const titleAnimation = (element, options) => {
      gsap.registerPlugin(ScrollTrigger);
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          start: width < 600 ? "top 80%" : "50% 90%"
        }
      });
      tl.to(element, {
        ...options
      });
    };
    titleAnimation(titleEl.current, {duration: 1, y: 0, ease: "power3.out"});
    titleAnimation(lineEl.current, {
      delay: -0.8,
      duration: 1.5,
      ease: "power3.out",
      width: "100%"
    });
  }, [width, title]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "title-container"
  }, /* @__PURE__ */ React.createElement("h2", {
    className: `title title-${title.replace(" ", "")}`,
    ref: titleEl
  }, title), /* @__PURE__ */ React.createElement("div", {
    className: `title-line title-line-${title.replace(" ", "")}`,
    ref: lineEl
  }));
};
