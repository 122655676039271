import React, {useEffect} from "../../../_snowpack/pkg/react.js";
import gsap from "../../../_snowpack/pkg/gsap.js";
import {useScroll} from "../../hooks/useScroll.js";
import "./Header.css";
export const Header = () => {
  const positionY = useScroll();
  const handleClick = (event) => {
    const target = event.target;
    const element = document.querySelector(`#${target.dataset.id}`);
    element && window.scrollTo(0, element.getBoundingClientRect().top + positionY);
  };
  useEffect(() => {
    const handleComplete = () => {
      document.body.style.overflow = "unset";
    };
    const tl = gsap.timeline({onComplete: handleComplete});
    tl.to(".navbar-list-item", {
      delay: 6.5,
      duration: 0.8,
      ease: "power3.out",
      opacity: 1,
      stagger: {each: 0.4}
    });
  }, []);
  return /* @__PURE__ */ React.createElement("header", {
    className: "header"
  }, /* @__PURE__ */ React.createElement("nav", {
    className: "navbar"
  }, /* @__PURE__ */ React.createElement("ul", {
    className: "navbar-list"
  }, /* @__PURE__ */ React.createElement("li", {
    className: "navbar-list-item",
    onClick: handleClick,
    "data-id": "about"
  }, "About"), /* @__PURE__ */ React.createElement("li", {
    className: "navbar-list-item",
    onClick: handleClick,
    "data-id": "projects"
  }, "Projects"), /* @__PURE__ */ React.createElement("li", {
    className: "navbar-list-item",
    onClick: handleClick,
    "data-id": "contact"
  }, "Contact"), /* @__PURE__ */ React.createElement("li", {
    className: "navbar-list-item"
  }, /* @__PURE__ */ React.createElement("a", {
    href: "https://github.com/IbnDaanis",
    target: "blank",
    rel: "noreferrer"
  }, "GitHub")))));
};
