import React, {useEffect} from "../../../_snowpack/pkg/react.js";
import gsap from "../../../_snowpack/pkg/gsap.js";
import "./Hero.css";
import {useScroll} from "../../hooks/useScroll.js";
export const Hero = ({introComplete}) => {
  const positionY = useScroll();
  useEffect(() => {
    if (introComplete) {
      const tl = gsap.timeline();
      tl.to(".line", {
        duration: 0.8,
        ease: Expo.easeIn,
        y: "100%",
        stagger: {each: 0.4}
      });
    }
  }, [introComplete]);
  return /* @__PURE__ */ React.createElement("section", {
    id: "hero",
    className: "hero"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "container"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "content"
  }, /* @__PURE__ */ React.createElement("h1", {
    className: "heading",
    style: {transform: `translateY(-${positionY * 0.1}px)`}
  }, /* @__PURE__ */ React.createElement("span", null, "I'm Ikraam"), /* @__PURE__ */ React.createElement("div", {
    className: "line"
  })), /* @__PURE__ */ React.createElement("h2", {
    className: "sub-heading",
    style: {transform: `translateY(-${positionY * 0.08}px)`}
  }, /* @__PURE__ */ React.createElement("span", null, "Front-End Developer. ", /* @__PURE__ */ React.createElement("div", {
    className: "line"
  })), /* @__PURE__ */ React.createElement("span", null, "Self Taught. From London.", /* @__PURE__ */ React.createElement("div", {
    className: "line"
  }))))));
};
